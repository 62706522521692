<template>
  <main :class="[typeColor,
              {'header-empty': (!titleCateActive && $route.name !== 'CategoryList.Search'), }]">
    <!-- content home page -->
    <div class="home-content container"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <sidebar class="pc" />

      <div class="wrap-content">
        <!-- list cate -->
        <router-view :title-cate="titleCateActive"
                     ref="childCate"
                     :type-color="typeColor"/>

        <!-- list ranking category -->
        <ListRanking :list-cate="rankingList" />
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from '@/layouts/Sidebar'
import ListRanking from '@/components/home/ListRanking'
import store from '@/store'
import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Index',

  components: { ListRanking, Sidebar },

  mixins: [Common],

  async mounted () {
    // update color page
    if (this.$route.query.typeColor) {
      this.typeColor = (this.$route.query.typeColor === '2') ? 'cate-pink' : null
    }

    // GET NAME CATE TO SHOW TITLE IN CATEGORY
    if (this.$route.params.id === 'all') {
      this.titleCateActive = '全てのカテゴリ'
    } else if (this.$route.query.level === 'point') {
      this.titleCateActive = '毎日見て貯まる！'
    } else if (this.$route.query.level === 'mid') {
      this.categoryListTitle.forEach(big => {
        big.children.forEach((mid) => {
          if (mid.id === this.$route.params.id) {
            this.titleCateActive = mid.title
          }
        })
      })
    } else {
      this.categoryListTitle.forEach(big => {
        big.children.forEach((mid) => {
          mid.children.forEach((small) => {
            if (small.id === this.$route.params.id) {
              this.titleCateActive = small.title
            }
          })
        })
      })
    }

    // define type filter
    let typeFilter = 1 // default is category
    switch (this.$route.name) {
      case 'CategoryList.Survey':
        typeFilter = 3; break
      case 'CategoryList.Receipt':
        typeFilter = 2; break
      case 'CategoryList.Search':
        typeFilter = null
    }

    // fetch data on page
    let params = {
      perPage: 20,
      page: 1,
      orderBy: 'publish_start_datetime',
      sortedBy: 'desc',
      // is_opening: 1,
      filter_advertise_type: typeFilter
    }

    if (this.$route.name === 'CategoryList.Search') {
      params = {
        ...params,
        // is_opening: 1,
        keyword: this.$route.query.keyword
      }
      await this.$store.dispatch('search/getListSearch', params)
    } else {
      // call api common for cate, survey, receipt list
      // check all to fetch api list advertise
      if (this.$route.params.id === 'all') {
        if (this.$route.query.level === 'new') {
          params = {
            perPage: 20,
            page: 1,
            filter_advertise_type: 1,
            orderBy: 'created_at',
            sortedBy: 'desc'
            // is_opening: 1
          }
        }
        await this.$store.dispatch('advertises/getListAdvertises', params)
      } else if (this.$route.query.level === 'point') {
        await this.$store.dispatch('advertises/getAdsGetPointList', params)
      } else if (this.$route.query.level === 'mid') {
        const res = await this.$store.dispatch('advertisesMid/getListAdvertisesByMid', [this.$route.params.id, params])
        this.$store.dispatch('category/setListCate', [this.$route.params.id, res])
      } else {
        await this.$store.dispatch('category/getListCate', [this.$route.params.id, params])
      }
    }

    // Set the flag so that when the data is finished rendering, if the data is empty, the text will be empty
    this.$refs.childCate.pageRender()

    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }

    // fetch list ranking
    await store.dispatch('categoryRanking/getList', { with: 'category,advertiseRankings.advertise' })
  },

  async created () {
    store.dispatch('category/getListTitle', { with: 'children.children' })

    if (this.$route.query.typeColor) {
      this.typeColor = (this.$route.query.typeColor === '2') ? 'cate-pink' : null
    }
    // GET NAME CATE TO SHOW TITLE IN CATEGORY
    if (this.$route.params.title) {
      this.titleCateActive = this.$route.params.title
    } else {
      if (this.$route.query.level === 'mid') {
        this.categoryListTitle.forEach(big => {
          big.children.forEach((mid) => {
            if (mid.id === this.$route.params.id) {
              this.titleCateActive = mid.title
            }
          })
        })
      } else {
        this.categoryListTitle.forEach(big => {
          big.children.forEach((mid) => {
            mid.children.forEach((small) => {
              if (small.id === this.$route.params.id) {
                this.titleCateActive = small.title
              }
            })
          })
        })
      }
    }
  },

  data () {
    return {
      typeColor: null,
      listCate: null,
      titleCateActive: null
    }
  },

  computed: {
    ...mapState('category', ['categoryListTitle', 'categoryList']),
    ...mapState('categoryRanking', ['rankingList']),
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }

}
</script>

<style lang="scss" scoped>
.home-content {
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  padding-top: 93px;
  padding-bottom: 96px;
  @media #{$info-phone} {
    display: block;
    padding-top: 45px;
    padding-bottom: 72px;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 92px;
    }
  }
  &.padding-webview {
    padding-top: 0;
    padding-bottom: 32px;
  }
  .wrap-content {
    flex: 1;
  }
}
main {
  background: #ffffff;
}
</style>
